body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  padding: 5px;
}

/* unvisited link */
a:link {
  color: #FA7070;
  text-decoration: none;
  font-weight: bold;
}

/* visited link */
a:visited {
  color: #FA7070;
  text-decoration: none;
  font-weight: bold;
}

/* mouse over link */
a:hover {
  color: #03C988;
  text-decoration: none;
  font-weight: bold;
}

/* selected link */
a:active {
  color: #FA7070;
  text-decoration: none;
  font-weight: bold;
}